<template>
	<div class="device-account">
		<gc-header header-data="timeoutDevice">
			<template #ops>
				<el-button v-click-blur @click="handleRecordExport">导 出</el-button>
			</template>
		</gc-header>
		<div class="content">
			<div class="filter">
				<gc-custom-search
					class="search"
					key-word="设备编号"
					type="input"
					width="300px"
					:search.sync="params['deviceNo']"
				/>
				<gc-custom-search
					class="search"
					v-if="tenantType == 1"
					keyWord="客户类型"
					width="300px"
					type="select"
					:search.sync="params['userType']"
					:search-option="searchOptions['userType']"
					:needAllForSearch="true"
					:searchOptionKey="{
						label: 'name',
						value: 'defaultValue',
					}"
				></gc-custom-search>
				<gc-custom-search
					v-if="tenantType != 1"
					class="search"
					key-word="设备类别"
					type="select"
					width="300px"
					filterable
					:search-option="searchOptions['firstCategory']"
					:search.sync="params['firstCategory']"
					needAllForSearch
				></gc-custom-search>
				<gc-custom-search
					v-if="tenantType != 1"
					class="search"
					key-word="设备类型"
					type="select"
					width="300px"
					filterable
					:search-option="searchOptions['deviceTypeId']"
					:search.sync="params['deviceTypeId']"
					needAllForSearch
				></gc-custom-search>
				<!-- <gc-custom-search
          class="search"
          key-word="设备状态"
          type="select"
          width="300px"
          :search-option="searchOptions['deviceStatus']"
          :search-option-key="{ label: 'name', value: 'defaultValue' }"
          :search.sync="params['deviceStatus']"
          needAllForSearch
        ></gc-custom-search> -->
				<gc-custom-search
					class="search"
					key-word="安装地址"
					type="input"
					width="300px"
					:search.sync="params['address']"
				></gc-custom-search>
				<div class="btn-ops">
					<el-button v-click-blur type="primary" class="query" @click="getDeviceList(1)">查 询</el-button>
					<el-button v-click-blur @click="reset">重 置</el-button>
				</div>
				<gc-column-setting
					:storageKey="`localcolumn-timeout-device-${tenantType}`"
					:columns="columns"
					@update-column="data => (columns = data)"
				></gc-column-setting>
			</div>
			<div class="table" v-loading="loading">
				<gc-table
					ref="deviceTable"
					border
					showPage
					:total="total"
					:columns="columnList"
					:table-data="tableData"
					:page-size="pageSize"
					:current-page.sync="currentPage"
					@size-change="handleSizeChange"
					@current-page-change="pageChange"
				></gc-table>
			</div>
		</div>
		<!--  -->
	</div>
</template>

<script>
import { apiGetDeviceList } from '@/apis/account.api'
import { isBlank } from '@/utils/validate'
import { mapActions } from 'vuex'
import { deviceDetailPermissions } from '@/config/const'
import { exportReport } from '@/apis/statisticAnalysis.api'
import { apiGetTenantConfigs } from '@/apis/tenant.api.js'

export default {
	name: 'deviceAccount',
	data() {
		return {
			dynomicKeys: [],
			searchOptions: {
				deviceTypeId: [],
				deviceStatus: [],
				firstCategory: [],
				userType: [],
			},
			params: {
				first: null,
				deviceTypeId: null,
				deviceStatus: null,
				installDate: [],
				firstCategory: null,
				userType: null,
			},
			tableData: [],
			total: 0,
			currentPage: 1,
			pageSize: 20,
			showDialog: false,
			title: '设备新增',
			dialogParams: {},
			dialogType: 'add',
			dialogChangeType: 0,
			pageParams: {},
			currentDeviceId: null,
			loading: false,
			dialogLoading: false,
			showUasyncBtn: true,
			columns: [],
		}
	},
	activated() {
		if (this.$route.params?.update) {
			// this.params = this.$options.data().params;
			this.params.firstCategory = this.$route.params.firstCategory
			this.params.deviceTypeId = null
			this.getDeviceList(1)
		}
	},
	created() {
		this.getTenantConfigs()
		if (this.$route.params?.firstCategory) {
			this.params.firstCategory = this.$route.params.firstCategory
		}
		this.getDeviceList(1)
		this.searchOptions.deviceStatus = this.$store.getters?.dataList?.deviceStatus || []
		this.searchOptions.userType = this.$store.getters?.dataList?.userType || []
		if (this.tenantType != 1) {
			this.getCategoryList()
		}
	},
	methods: {
		...mapActions({
			getFirstCategory: 'common/getFirstCategory',
			judgeNeedPickOrg: 'user/judgeNeedPickOrg',
			getDeviceTypes: 'common/getAllDeviceTypeList',
		}),
		getCategoryList() {
			this.getFirstCategory().then(data => {
				this.$set(
					this.searchOptions,
					'firstCategory',
					data.map(item => {
						return {
							label: item.name,
							value: item.productCategoryId,
						}
					}),
				)
			})
		},
		getDeviceTypeOptions() {
			this.getDeviceTypes({
				firstCategory: this.params.firstCategory || '',
			}).then(data => {
				this.$set(this.searchOptions, 'deviceTypeId', data)
			})
		},
		// 台账导出
		handleRecordExport() {
			if (!this.tableData.length) {
				this.$message.warning('暂无表格数据')
				return
			}
			const params = this.getRequestParams()
			if (!params) return
			this.$toast('下载中，请稍后...')
			const data = {
				method: 'post',
				url: '/monitor/report/device-ledger/export/excel',
				fileName: '超使用年限设备列表.xlsx',
				data: { ...params, overdueFlag: 1, tenantType: this.tenantType },
			}
			exportReport(data, 'excel', '', this.$toast)
		},
		getTenantConfigs() {
			apiGetTenantConfigs({ tenantId: this.tenantId }).then(res => {
				const list = res.dataList || []
				list.forEach(item => {
					if (item.key === 'enable_uasync') {
						this.showUasyncBtn = item.value === 'true'
					}
				})
			})
		},
		checkDetail(row) {
			this.$router.push({
				name: 'deviceDetail', // timeoutDeviceDetail
				query: {
					deviceId: row.deviceId,
					deviceType: row.deviceTypeId,
					source: 'timeoutDevice',
				},
			})
		},
		getRequestParams() {
			const params = {}
			for (const key in this.params) {
				const value = this.params[key]
				if (!isBlank(value)) {
					if (key === 'installDate' && value.length) {
						params.installStartDate = this.dayjs(value[0]).format('YYYY-MM-DD')
						params.installEndDate = this.dayjs(value[1]).format('YYYY-MM-DD')
					}
				}
				params[key] = value
			}
			delete params.installDate
			if (params.address && params.address.length < 2) {
				this.$message.warning('安装地址不可以少于2个字符')
				return
			}
			return params
		},
		handleSizeChange(size) {
			this.pageSize = size
			this.getDeviceList(1)
		},
		getDeviceList(current) {
			const data = this.getRequestParams()
			if (!data) return
			data.current = current
			data.size = this.pageSize
			this.loading = true
			apiGetDeviceList({ ...data, overdueFlag: 1, tenantType: this.tenantType })
				.then(res => {
					this.tableData = res.records || []
					this.total = res.total
					this.currentPage = res.current
				})
				.catch(() => {})
				.finally(() => {
					this.loading = false
				})
		},
		pageChange(current) {
			this.getDeviceList(current)
		},
		reset() {
			for (var key in this.params) {
				this.params[key] = null
			}
			this.getDeviceList(1)
		},
		getDynomicKeys(list) {
			this.dynomicKeys = list
		},
	},
	watch: {
		'params.firstCategory': {
			handler() {
				if (this.tenantType != 1) {
					this.params.deviceTypeId = null
					this.getDeviceTypeOptions()
				}
			},
			immediate: true,
		},

		tenantType: {
			handler(newVal) {
				this.columns = [
					{
						key: 'deviceNo',
						name: '设备编号',
						tooltip: true,
						fixed: 'left',
						dragDisable: true,
						disabledSelect: true,
					},
					{
						key: 'firstCategoryName',
						name: '设备类别',
						tooltip: true,
					},
					{
						key: 'deviceTypeName',
						name: '设备类型',
						tooltip: true,
					},
					{
						key: 'userNo',
						name: '用户号',
						tooltip: true,
					},
					{
						key: 'username',
						name: '客户/站点名称',
						tooltip: true,
					},
					{
						key: 'userPhone',
						name: '联系电话',
						tooltip: true,
					},
					{
						key: 'addressName',
						name: '安装地址',
						tooltip: true,
						width: 200,
					},
					{
						key: 'firstInstallDate',
						name: '安装时间',
					},
					{
						key: 'useYear',
						name: '设备寿命',
						tooltip: true,
						render: (h, row) => {
							const { useYear } = row
							return h('span', {}, isBlank(useYear) ? '--' : useYear + '年')
						},
					},
					{
						key: 'overdueDate',
						name: '到期时间',
					},
					{
						key: 'oprate',
						name: '操作',
						width: '250px',
						fixed: 'right',
						render: (h, row) => {
							// 根据安装状态进行动态变化
							// 如果状态是异常则展示或者没有任何操作权限  /
							if (isBlank(row.deviceStatus)) {
								return h('span', { style: { color: '#CCCCCC' } }, '/')
							}
							let style = {
								'font-size': '14px',
								'font-family': 'SourceHanSansCN-Regular, SourceHanSansCN',
								'font-weight': '400',
								color: '#4A69FF',
								'line-height': '21px',
								'margin-right': '20px',
								cursor: 'pointer',
							}
							return h('div', {}, [
								...[
									this.$has(deviceDetailPermissions)
										? [
												h(
													'span',
													{
														on: {
															click: () => this.checkDetail(row),
														},
														style,
													},
													'查看详情',
												),
										  ]
										: [],
								],
							])
						},
					},
				]
				if (newVal === '1') {
					this.columns.splice(1, 2, {
						key: 'userTypeName',
						name: '客户类型',
						tooltip: true,
					})
				}
			},
			immediate: true,
		},
	},
	computed: {
		tenantId() {
			return this.$store.getters.userInfo.tenantId || null
		},
		isOnlineOptions() {
			return this.$store.getters.dataList.isOnline || []
		},
		isAlarmOptions() {
			return this.$store.getters.dataList.isAlarm || []
		},
		tenantType() {
			return this.$store.getters.userInfo.tenantType || '0'
		},
		columnList() {
			return this.columns.filter(o => o.selected)
		},
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			let needRefresh = vm.$store.getters.needRefresh
			if (needRefresh) {
				vm.reset()
				vm.$store.dispatch('account/changeStatus', false)
			}
		})
	},
}
</script>
<style lang="scss" scoped>
.device-account {
	@include base-button(80px);
	height: 100%;
	//display: flex;
	//flex-direction: column;
	//align-content: flex-start;
	.content {
		//flex: 1;
		display: flex;
		flex-direction: column;
		height: calc(100% - 73px);
		box-sizing: border-box;
		padding: 20px 20px 0 24px;
		.filter {
			display: flex;
			flex-wrap: wrap;
			.search {
				margin-bottom: 14px;
				margin-right: 20px;
			}
			.btn-ops {
				@include base-button;
			}
		}
		.table {
			flex: 1;
			height: 0;
		}
	}
}
</style>
